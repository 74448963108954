


































import { Component, Vue } from 'vue-property-decorator';

import PasswordChangeModal from './PasswordChangeModal.vue';

@Component({
  name: 'Profile',
  components: {
    PasswordChangeModal,
  },
})
export default class extends Vue {
  model = 15;

  onChangePasswordModalOpen() {
    (<any>(this.$refs.changePasswordModal)).showModal();
  }
}
